import api from "../../api/axios";
import { actions, getActionURL, getDetailURL, getLabelFromKey, } from "./conventions";
import { hasPermission } from "./spec/permissions";
export function createPreprocessor(config) {
    const m = Object.entries(config);
    return (data, spec) => {
        return m.reduce((a, [key, value]) => {
            a[key] =
                typeof value === "string" && value[0] === "{"
                    ? spec.columns[value.slice(1, -1)]?.select(data)
                    : value;
            return a;
        }, {});
    };
}
export function createDataAction(props, target = null) {
    if (("action" in props || !("execute" in props)) && !target) {
        return new APIAction(props);
    }
    else if (!target)
        target = props;
    target.icon = props.icon ?? actions.default.icon;
    target.label = props.label ?? getLabelFromKey(props.action) ?? "";
    target.defaultColor = props.defaultColor ?? actions.default.color ?? "";
    target.detail = props.detail ?? false;
    target.reloadMode = props.reloadMode ?? false;
    target.bulk = props.bulk ?? false;
    if (target !== props) {
        target.form = props.form;
        target.requiredPermissions = props.requiredPermissions;
        target.preprocess = props.preprocess
            ? typeof props.preprocess === "function"
                ? props.preprocess
                : createPreprocessor(props.preprocess)
            : undefined;
        target.confirmationText = props.confirmationText;
        target.failureResponse = props.failureResponse;
        target.successResponse = props.successResponse;
        target.resultSpec = props.resultSpec;
    }
    target.canExecute =
        props.canExecute && props.canExecute !== hasActionPermissions
            ? props.canExecute
            : props.requiredPermissions && props.requiredPermissions.length
                ? hasActionPermissions
                : undefined;
    if ("execute" in props) {
        target.execute = props.execute;
    }
    return target;
}
function hasActionPermissions(user) {
    return hasPermission(user, this.requiredPermissions || []);
}
export class APIAction {
    method;
    action;
    icon;
    detail;
    reloadMode;
    label;
    confirmationText;
    defaultColor;
    requiredPermissions;
    resultSpec;
    successResponse;
    failureResponse;
    form;
    bulk;
    preprocess;
    canExecute;
    constructor(props) {
        createDataAction(props, this);
        this.action = props.action ?? "";
        this.method = props.method ?? "POST";
    }
    getBody(data, spec) {
        if (this.form) {
            return prepareForUpload(data, spec);
        }
        return undefined;
    }
    async execute(data, spec, url = spec.restURL) {
        url = this.action
            ? getActionURL(this.detail
                ? getDetailURL(url, spec.columns[spec.pk].select(data))
                : url, this.action)
            : url;
        url = url.replace("{" + spec.urlLookup + "}", spec.columns[spec.pk].select(data));
        try {
            const res = await api.request({
                url: url,
                method: this.method,
                data: await this.getBody(data, spec),
            });
            return {
                success: true,
                successResponse: res.data.message,
                shouldReload: this.reloadMode,
                rawResponse: res,
            };
        }
        catch (e) {
            let err = e;
            return {
                success: false,
                error: err.message,
                shouldReload: this.reloadMode,
                rawResponse: err.response?.data,
            };
        }
    }
}
export class SendBodyAPIAction extends APIAction {
    constructor(props) {
        if (!props.form)
            props.form = true;
        super(props);
    }
}
async function prepareForUpload(data, spec) {
    if ("columns" in spec) {
        let m;
        for (const i in spec.columns) {
            if (i in data) {
                (m || (m = { ...data }))[i] = await prepareForUpload(spec.columns[i].select(data), spec.columns[i]);
            }
        }
        return m ?? data;
    }
    else if (spec.type === "image" || spec.type === "file") {
        if (typeof data === "string") {
            return undefined;
        }
        return data;
    }
    else if (spec.type === "list") {
        return (data &&
            (await Promise.all(data?.map((e) => prepareForUpload(e, spec.listType)))));
    }
    else {
        return data;
    }
}
export const APIActions = {
    Create: new APIAction({
        method: "POST",
        detail: false,
        form: true,
        icon: actions.create.icon,
        label: actions.create.label,
        defaultColor: actions.create.color,
    }),
    Update: new APIAction({
        method: "PATCH",
        detail: true,
        form: true,
        icon: actions.create.icon,
        label: actions.create.label,
        defaultColor: actions.update.color,
        reloadMode: "partial",
    }),
    Delete: new APIAction({
        method: "DELETE",
        confirmationText: "Delete this item?",
        detail: true,
        label: actions.delete.label,
        icon: actions.delete.icon,
        defaultColor: actions.delete.color,
    }),
};
