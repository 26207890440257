import { useRef } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import "./table.css";
export default function Pagination({ page, setPage, pageSize, count }) {
  const numPages = Math.floor((count - 1) / pageSize) + 1;
  return (
    <div
      style={{
        flexDirection: "row",
        gap: "0.5em",
        display: "flex",
        alignItems: "center",
      }}
    >
      <button
        className="one-table-button one-table-button-primary-outlined"
        style={{
          textAliggn: "center",
          padding: "0 8px",
          width: "40px",
        }}
        disabled={page == 1}
        onClick={() => setPage(page - 1)}
      >
        <FiArrowLeft size={24} color="inherit" />
      </button>
      {page === numPages && page >= 3 ? (
        <button
          className="one-table-button one-table-button-outlined"
          onClick={() => setPage(page - 2)}
        >
          {page - 2}
        </button>
      ) : null}
      {page === 1 ? null : (
        <button
          className="one-table-button one-table-button-outlined"
          onClick={() => setPage(page - 1)}
        >
          {page - 1}
        </button>
      )}
      <button className="one-table-button one-table-button-primary">
        {page}
      </button>
      {page < numPages ? (
        <button
          className="one-table-button one-table-button-outlined"
          onClick={() => setPage(page + 1)}
        >
          {page + 1}
        </button>
      ) : null}
      {page === 1 && numPages >= 3 ? (
        <button
          className="one-table-button one-table-button-outlined"
          onClick={() => setPage(3)}
        >
          3
        </button>
      ) : null}
      <button
        className="one-table-button one-table-button-primary-outlined"
        style={{
          textAliggn: "center",
          padding: "0 8px",
          width: "40px",
        }}
        disabled={page >= numPages}
        onClick={() => setPage(page + 1)}
      >
        <FiArrowRight size={24} color="inherit" />
      </button>
    </div>
  );
}
