import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import Table from "../../../component/table/Table";
import { createSpec } from "../../../component/admin-one/spec/createSpec";

function AdminRoles() {
  const navigate = useNavigate();

  return (
    <div className="br__novel">
      <Table
        title="Manage Roles"
        config={{
          listActions: [],
          showSN: true,
          spec: createSpec({
            restURL: "/roles",
            columns: {
              id: "string",
              name: "string",
            },
            actions: [],
          }),
          onSelectRow: {
            execute(data) {
              navigate("/roles/" + data.id);
            },
          },
          tableColumns: ["name", "email", "phone_number", "is_active"],
        }}
      />
    </div>
  );
}

export default AdminRoles;
