import { useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import "./admin-roles-single.css";
import { useGetRolePermissions } from "../../../redux/actions/rolePermissionAction";
import useQuery from "../../../component/table/useQuery";
import sentenceCase from "../../../utils/sentence_case";

// Define sections and permissions here
const sections = [
  {
    id: "novel",
    title: "Novel Section",
    permissions: ["Create", "Edit", "Delete"],
  },
  {
    id: "user",
    title: "User Section",
    permissions: ["Create", "Edit", "Delete"],
  },
  // Add more sections as needed
];

function AdminRolesSingle() {
  const { id } = useParams();
  const allRoles = useQuery("/permissions/all/");
  const groupRoles = useQuery("/roles/" + id + "/permissions/");
  const group = useQuery("/roles/" + id + "/");

  /**
   * @type {Record<string, {permission:string, label:string}[]>}
   */
  const parsedPermissions = useMemo(() => {
    const roles = allRoles.response?.permissions;
    if (!roles) return {};
    return roles.reduce((sections, item) => {
      const [action, ...parts] = item.name.replace(/^Can /, "").split(" ");

      const section = sentenceCase(parts.join(" "));

      if (!sections[section]) {
        sections[section] = [
          {
            permission: item.codename,
            label: action,
          },
        ];
      } else {
        sections[section].push({
          permission: item.codename,
          label: action,
        });
      }
      return sections;
    }, {});
  }, [allRoles.response?.permissions]);

  const [checkedPermissions, setCheckedPermissions] = useState({});

  const handleCheckAll = (sectionId, isChecked) => {
    console.log(sectionId, isChecked);
    setCheckedPermissions((prev) => ({
      ...prev,
      ...parsedPermissions[sectionId].reduce((pAcc, perm) => {
        pAcc[perm.permission] = isChecked;
        return pAcc;
      }, {}),
    }));
  };

  const handleCheckPermission = (sectionId, permission, isChecked) => {
    setCheckedPermissions((prev) => ({
      ...prev,
      [permission.permission]: isChecked,
    }));
  };

  const handlePageCheckAll = (isChecked) => {
    setCheckedPermissions(
      Object.keys(parsedPermissions).reduce((acc, section) => {
        parsedPermissions[section].reduce((pAcc, perm) => {
          pAcc[perm.permission] = isChecked;
          return pAcc;
        }, acc);
        return acc;
      }, {})
    );
  };

  const allChecked = useMemo(() => {
    return Object.keys(parsedPermissions).every((e) =>
      parsedPermissions[e].every((e) => !!checkedPermissions[e.permission])
    );
  }, [checkedPermissions, parsedPermissions]);

  return (
    <div className="admin-roles-single">
      <h1 className="admin-roles-single__title">
        Roles for: {group?.response?.name}
      </h1>
      <label className="admin-roles-single__page-check-all">
        <input
          type="checkbox"
          checked={allChecked}
          onChange={(e) => handlePageCheckAll(e.target.checked)}
        />
        Check All Sections
      </label>
      <div className="admin-roles-single__section__wrap">
        {Object.keys(parsedPermissions).map((section) => (
          <div key={section} className="admin-roles-single__section">
            <h3 className="admin-roles-single__section-title">{section}</h3>
            <label className="admin-roles-single__section-check-all">
              <input
                type="checkbox"
                checked={parsedPermissions[section].every(
                  (e) => checkedPermissions[e.permission]
                )}
                onChange={(e) => handleCheckAll(section, e.target.checked)}
              />
              Check All
            </label>
            <div className="admin-roles-single__permissions">
              {parsedPermissions[section].map((perm) => (
                <label
                  key={perm.permission}
                  className="admin-roles-single__permission"
                >
                  {perm.label}
                  <input
                    type="checkbox"
                    checked={checkedPermissions[perm.permission] || false}
                    onChange={() =>
                      handleCheckPermission(
                        section,
                        perm,
                        !checkedPermissions[perm.permission]
                      )
                    }
                  />
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminRolesSingle;
