import { useEffect, useMemo, useRef, useState } from "react";
import api from "../../api/axios";
import { normalizeURL } from "../admin-one/conventions";

export default function useQuery(url) {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(1);
  const [fetchError, setFetchError] = useState(false);
  const prevPageCount = useRef();

  useEffect(() => {
    setResponse(null);
  }, [url]);
  useEffect(() => {
    if (!url) return;
    setLoading(true);
    setFetchError(null);
    let cancellationToken = false;
    api
      .get(normalizeURL(url))
      .then((res) => {
        if (!cancellationToken) {
          setResponse(res.data);
          prevPageCount.current = res.data.count;
          setLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        if (!cancellationToken) {
          prevPageCount.current = response?.count;
          setFetchError(e.data?.error || e.message || "An error occured");
          setLoading(false);
        }
      });
    return () => {
      prevPageCount.current = response?.count;
      setLoading(false);
      cancellationToken = true;
    };
  }, [url, reload]);

  return useMemo(
    () => ({
      ...(response ? { response } : {}),
      count: prevPageCount.current,
      loading,

      fetchError,
      reload: () => {
        setReload((reload) => reload + 1);
      },
    }),
    [loading, fetchError, response]
  );
}
