export default class BulkAction {
    action;
    bulk;
    icon;
    detail;
    label;
    confirmationText;
    defaultColor;
    requiredPermissions;
    resultSpec;
    canExecute;
    constructor(action) {
        this.action = action;
        this.bulk = true;
        this.icon = action.icon;
        this.detail = action.detail;
        this.label = action.label;
        this.confirmationText = action.confirmationText;
        this.defaultColor = action.defaultColor;
        this.requiredPermissions = action.requiredPermissions;
        this.resultSpec = action.resultSpec;
        this.canExecute = action.canExecute;
    }
    async preprocess(data, spec) {
        return this.action.preprocess
            ? data?.map((e) => this.action.preprocess(e, spec))
            : data;
    }
    async execute(data, spec) {
        const res = await Promise.allSettled(data.map(async (e) => this.action.execute(e, spec)));
        const failureResult = res.find((e) => e.status === "rejected" || e.value?.success === false);
        if (!failureResult) {
            return (res[0]?.value ?? {
                success: true,
                successResponse: this.action.successResponse,
                shouldReload: this.action.reloadMode,
            });
        }
        else if (failureResult.status === "rejected") {
            return {
                success: false,
                shouldReload: this.action.reloadMode,
                error: failureResult.reason.message,
                rawResponse: failureResult.reason,
            };
        }
        else {
            return failureResult.value;
        }
    }
}
