import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import Table from "../../../component/table/Table";

function AdminEditorial() {
  const navigate = useNavigate();

  return (
    <div className="br__novel">
      <Table
        title="New Contracts"
        // There should be a phone number type
        // Form might want to make use of redux for useField and formContext
        config={{
          listActions: [],
          showSN: true,
          spec: {
            ...resources.novels,
            restURL: "/novels?listing=editorialPicks",
          },
          tableColumns: ["title", "author", "genres", "num_chapters"],
        }}
      />
    </div>
  );
}

export default AdminEditorial;
