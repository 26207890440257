import { useNavigate } from "react-router-dom";
import api from "../../../api/axios";
import { resources } from "../../../api/resources";
import { SendBodyAPIAction } from "../../../component/admin-one/APIAction";
import { createSpec } from "../../../component/admin-one/spec/createSpec";
import BulkAction from "../../../component/admin-one/BulkAction";
import Table from "../../../component/table/Table";

import "./admin-novels.css";

function AdminNovel() {
  return (
    <ManageNovels
      spec={resources.novels}
      title="All Novels"
      columns={[
        "id",
        "title",
        "author",
        "genres",
        "num_chapters",
        "num_views",
        "contract_status",
        "editor",
      ]}
    />
  );
}
export function ManageNovels({ spec, title, columns }) {
  const navigate = useNavigate();

  return (
    <div className="br__novel">
      <Table
        title={title}
        // override renderForm, renderShow and renderCard at the view level
        config={{
          bulkActions: [
            new BulkAction(
              new SendBodyAPIAction({
                detail: true,
                label: "Approve All",
                action: "/contracts/{novelId}/approve/",
                method: "POST",
              })
            ),
          ],
          listActions: [],
          onSelectRow: {
            execute(data) {
              navigate("/novels/" + data.id);
            },
          },
          showExport: true,
          spec: {
            ...spec,
            actions: [
              {
                label: "Assign Editor",
                preprocess(e) {
                  return {
                    id: e.id,
                    editor: e.editor?.id,
                  };
                },
                form: {
                  ...resources.novels,
                  label: "Assign Editor",
                  columns: {
                    editor: resources.novels.columns.editor,
                  },
                },
                async execute(data) {
                  return api
                    .post("/novels/admin/" + data.id + "/assign-editor/", data)
                    .then((e) => ({
                      success: true,
                      successResponse: e.message || "Editor has been assigned",
                      shouldReload: "partial",
                    }));
                },
              },
              new SendBodyAPIAction({
                detail: true,
                label: "Approve",
                action: "/contracts/{novelId}/approve/",
                method: "POST",
              }),
              {
                label: "Reject Novel",
                form: createSpec({
                  label: "Reject Novel",
                  columns: {
                    reason: {
                      type: "string",
                      stringType: "longtext",
                    },
                  },
                }),
                preprocess(e) {
                  return {
                    novelId: e.id,
                  };
                },
                async execute(data, spec) {
                  return api
                    .post("/contracts/" + data.novelId + "/reject/", data)
                    .then((e) => ({
                      success: true,
                      successResponse: e.message || "Novel has been rejected.",
                      shouldReload: "partial",
                    }));
                },
              },
            ],
          },
          tableColumns: columns,
        }}
      />
    </div>
  );
}

export default AdminNovel;
