import { ClipLoader } from "react-spinners";
import { HIDDEN_RENDERERS } from "../admin-one/spec/registerRenderer";
import { registerRenderer } from "../admin-one/spec/registerRenderer";
import FormComponent from "../forms/FormComponent";
import useQuery from "./useQuery";
import { useField } from "../forms/AppForm";
HIDDEN_RENDERERS.clear();
registerRenderer("string", {
  view: "table",
  renderer: ({ data }) => {
    return String(data);
  },
});
registerRenderer("string", {
  view: "table",
  test(e) {
    return e.options;
  },
  renderer: ({ data, spec }) => {
    return String(spec.options[data] ?? data);
  },
});

registerRenderer("string", {
  view: "form",
  renderer: ({ spec }) => {
    return (
      <FormComponent name={spec.key}>
        {({ value, update, error }) => (
          <div style={{ width: "100%", maxWidth: "480px" }}>
            <label for={spec.key} style={{ color: "#777787", fontSize: 12 }}>
              {spec.label}
            </label>
            <input
              style={{
                display: "block",
                width: "100%",
                padding: "0.5em",
                borderRadius: "0.5em",
              }}
              id={spec.key}
              name={spec.key}
              value={value ?? null}
              onChange={(e) => update(e.target.value)}
            />
            <FormError name={spec.key} />
          </div>
        )}
      </FormComponent>
    );
  },
});

registerRenderer("string", {
  view: "form",
  test(e) {
    return !!e.options;
  },
  renderer: ({ spec }) => {
    return (
      <FormComponent name={spec.key}>
        {({ value, update, error }) => (
          <div style={{ width: "100%", maxWidth: "480px" }}>
            <label for={spec.key} style={{ color: "#777787", fontSize: 12 }}>
              {spec.label}
            </label>
            <select
              style={{
                display: "block",
                width: "100%",
                padding: "0.5em",
                borderRadius: "0.5em",
              }}
              id={spec.key}
              name={spec.key}
              value={value ?? null}
              onChange={(e) => update(e.target.value)}
            >
              {Object.keys(spec.options).map((key) => (
                <option key={key} value={key}>
                  {spec.options[key]}
                </option>
              ))}
            </select>
            <FormError name={spec.key} />
          </div>
        )}
      </FormComponent>
    );
  },
});

registerRenderer("number", {
  view: "form",
  renderer: ({ spec }) => {
    return (
      <FormComponent name={spec.key}>
        {({ value, update, error }) => (
          <div style={{ width: "100%", maxWidth: "480px" }}>
            <label for={spec.key} style={{ color: "#777787", fontSize: 12 }}>
              {spec.label}
            </label>
            <input
              type="number"
              style={{
                display: "block",
                width: "10em",
                padding: "0.5em",
                borderRadius: "0.5em",
              }}
              id={spec.key}
              name={spec.key}
              value={value ?? null}
              onChange={(e) => update(e.target.value)}
            />
            <FormError name={spec.key} />
          </div>
        )}
      </FormComponent>
    );
  },
});
registerRenderer("boolean", {
  view: "form",
  renderer: ({ spec }) => {
    return (
      <FormComponent name={spec.key}>
        {({ value, update, error }) => (
          <div style={{ width: "100%", maxWidth: "480px" }}>
            <div>
              <input
                type="checkbox"
                style={{
                  marginRight: "1em",
                  borderRadius: "0.5em",
                }}
                id={spec.key}
                name={spec.key}
                checked={value ?? null}
                onChange={(e) => update(e.target.checked)}
              />
              <label for={spec.key} style={{ fontSize: 16 }}>
                {spec.label}
              </label>
            </div>
            <FormError name={spec.key} />
          </div>
        )}
      </FormComponent>
    );
  },
});

registerRenderer("list", {
  view: "form",
  renderer({ spec }) {
    return null;
  },
});
registerRenderer("image", {
  view: "form",
  renderer: ({ spec }) => {
    return (
      <FormComponent name={spec.key}>
        {({ value, update, error }) => (
          <div style={{ width: "100%", maxWidth: "480px" }}>
            <label for={spec.key} style={{ color: "#777787", fontSize: 12 }}>
              {spec.label}
            </label>
            <input
              type="file"
              accept="image/*"
              style={{
                display: "block",
                width: "100%",
                padding: "0.5em",
                borderRadius: "0.5em",
              }}
              id={spec.key}
              name={spec.key}
              value={value ?? null}
              onChange={(e) => update(e.target.value)}
            />
            <FormError name={spec.key} />
          </div>
        )}
      </FormComponent>
    );
  },
});

registerRenderer("datetime", {
  view: "table",
  renderer: ({ data }) => {
    return new Date(data).toLocaleString();
  },
});
registerRenderer("datetime", {
  view: "form",
  renderer: ({ spec }) => {
    return (
      <FormComponent name={spec.key}>
        {({ value, update, error }) => (
          <div style={{ width: "100%", maxWidth: "480px" }}>
            <label for={spec.key} style={{ color: "#777787", fontSize: 12 }}>
              {spec.label}
            </label>
            <input
              type="datetime"
              style={{
                display: "block",
                width: "10em",
                padding: "0.5em",
                borderRadius: "0.5em",
              }}
              id={spec.key}
              name={spec.key}
              value={value ?? null}
              onChange={(e) => update(e.target.value)}
            />
            <FormError name={spec.key} />
          </div>
        )}
      </FormComponent>
    );
  },
});

registerRenderer("string", {
  view: "form",
  test(e) {
    return e.stringType === "longtext";
  },
  renderer: ({ data, spec }) => {
    return (
      <FormComponent name={spec.key}>
        {({ value, update, error }) => (
          <div style={{ width: "100%", maxWidth: "480px" }}>
            <label for={spec.key} style={{ color: "#777787", fontSize: 12 }}>
              {spec.label}
            </label>
            <textarea
              style={{
                display: "block",
                width: "100%",
                minHeight: "5em",
                maxHeight: "7em",
                padding: "0.5em",
                borderRadius: "0.5em",
              }}
              id={spec.key}
              name={spec.key}
              value={value ?? null}
              onChange={(e) => update(e.target.value)}
            ></textarea>
            <FormError name={spec.key} />
          </div>
        )}
      </FormComponent>
    );
  },
});

registerRenderer("number", {
  view: "table",
  renderer: ({ data }) => {
    return String(data);
  },
});

registerRenderer("list", {
  view: "table",
  renderer: ({ data, spec, meta }) => {
    return (
      <div>
        {data.map((e) =>
          spec.listType.renderTable({
            data: e,
            spec: spec.listType,
            meta: meta,
          })
        )}
      </div>
    );
  },
});

registerRenderer("reference", {
  view: "table",
  renderer: ({ data, spec, meta }) => {
    const k = spec.referenceSpec(data);
    return String(
      typeof data === "object" && data
        ? k.columns[k.slots.title].select(data)
        : data ?? "--"
    );
  },
});

registerRenderer("reference", {
  view: "form",
  renderer: ({ data, spec, meta }) => {
    const k = spec.referenceSpec(data);
    return (
      <FormComponent name={spec.key} spec={k}>
        {(props) => (
          <div style={{ width: "100%", maxWidth: "480px" }}>
            <label for={spec.key} style={{ color: "#777787", fontSize: 12 }}>
              Pick {spec.label}
            </label>
            <FormError name={spec.key} />
            <RefPicker {...props} />
          </div>
        )}
      </FormComponent>
    );
  },
});

registerRenderer("boolean", {
  view: "table",
  renderer: ({ data }) => {
    return data == null ? "--" : data ? "Yes" : "No";
  },
});

function FormError({ name }) {
  const field = useField(name);
  return (
    <div style={{ color: "red", fontSize: "12", fontWeight: 400 }}>
      {field.error}
    </div>
  );
}

/**
 *
 * @param {object} param0
 * @param {import("../admin-one/types").DataSpec} param0.spec
 */
function RefPicker({ spec, value: selectedItem, update: setSelectedItem }) {
  const {
    fetchError: errorMessage,
    loading,
    response: { results } = { results: [] },
  } = useQuery(spec.restURL);
  const titleSpec = spec.columns[spec.slots.title || ""];
  const pkSpec = spec.columns[spec.pk];

  return (
    <div style={{ width: "100%" }}>
      {errorMessage && (
        <p
          style={{
            color: "red",
          }}
        >
          {errorMessage}
        </p>
      )}
      {loading ? (
        <ClipLoader size={20} />
      ) : (
        results.map((element, index) => {
          const pk = pkSpec.select(element);
          console.log({ pk });
          return (
            <div
              key={index}
              style={{
                border: "1px solid gray",
                padding: "10px",
                width: "100%",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={(e) => setSelectedItem(pk)}
            >
              <input
                type="radio"
                name="editor"
                checked={selectedItem === pk}
                value={pk}
                onChange={() => setSelectedItem(pk)}
              />
              {spec.columns[spec.slots.title || ""].renderInline({
                spec: titleSpec,
                data: titleSpec.select(element),
              })}
            </div>
          );
        })
      )}
    </div>
  );
}
