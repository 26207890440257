import React, { createContext, useContext, useEffect, useMemo, useRef, useState, } from "react";
const context = createContext(null);
const onErrorDefault = () => { };
export default function Form({ onSubmit, formRef, children, initialValue, onValidate, onError = onErrorDefault, onChange, }) {
    const [data, setData] = useState(initialValue ?? {});
    const [errorInfo, setErrorInfo] = useState(null);
    const callbacks = useRef({ onSubmit, onChange, onValidate });
    callbacks.current.onChange = onChange;
    callbacks.current.onValidate = onValidate;
    callbacks.current.onSubmit = onSubmit;
    useEffect(() => {
        if (callbacks.current.onValidate) {
            let cancelled = false;
            const deferred = setTimeout(async () => {
                if (callbacks.current.onValidate &&
                    errorInfo &&
                    (errorInfo.error || errorInfo.errors)) {
                    const result = await callbacks.current.onValidate(data);
                    if (!cancelled)
                        setErrorInfo(result);
                }
            }, 1000);
            return () => {
                cancelled = true;
                clearTimeout(deferred);
            };
        }
    }, [data, errorInfo]);
    const value = useMemo(() => {
        const setError = (e) => {
            setErrorInfo(e);
            if (e) {
                onError(e);
            }
        };
        return {
            data,
            setError,
            ...errorInfo,
            submit: async () => {
                const errorInfo = callbacks.current.onValidate
                    ? await callbacks.current.onValidate(data)
                    : null;
                setError(errorInfo);
                if (errorInfo)
                    return;
                try {
                    await callbacks.current.onSubmit?.(data);
                }
                catch (e) {
                    console.error(e);
                    setError({ error: e.message });
                }
            },
            update(path, value) {
                const _isArray = Array.isArray;
                let x = _isArray(data) ? data.slice() : { ...data };
                const y = x;
                for (let i = 0; i < path.length - 1; i++) {
                    const isArray = x[path[i]]
                        ? _isArray(x[path[i]])
                        : typeof path[i + 1] === "number";
                    x = x[path[i]] = isArray
                        ? [...(x[path[i]] || [])]
                        : { ...(x[path[i]] || {}) };
                }
                if (value === undefined && _isArray(x)) {
                    x.splice(path[path.length - 1], 1);
                }
                else
                    x[path[path.length - 1]] = value;
                setData(y);
                if (callbacks.current.onChange) {
                    callbacks.current.onChange(y);
                }
            },
            reset: setData,
        };
    }, [data, errorInfo]);
    useEffect(() => {
        if (formRef) {
            formRef.current = value;
            return () => void (formRef.current = null);
        }
    }, [formRef, value]);
    return <context.Provider value={value}>{children}</context.Provider>;
}
export const useField = function (path_or_name) {
    const path = useMemo(() => {
        if (typeof path_or_name === "string")
            return path_or_name
                .split(/[[\].]/)
                .filter(Boolean)
                .map((e) => (/^\d+$/.test(e) ? parseInt(e) : e));
        else
            return path_or_name ?? ["?"];
    }, [path_or_name]);
    const m = useContext(context);
    const error = path.reduce((acc, e) => acc?.[e], m.errors);
    return useMemo(() => ({
        value: path.reduce((acc, e) => acc?.[e], m.data),
        error: Array.isArray(error) ? error.join(". ") + "." : error,
        update(value) {
            m.update(path, value);
        },
    }), [...path, m]);
};
export function FormArray({ name, renderEach, renderHeader, renderFooter, ...props }) {
    const field = useField(name);
    if (!field.value)
        field.value = [];
    return (<>
      {renderHeader ? renderHeader(field.value, field.update) : null}
      {field.value.map((e, i) => {
            return renderEach(e, i, (value) => {
                field.update(value === undefined
                    ? [...field.value.slice(0, i), ...field.value.slice(i + 1)]
                    : [
                        ...field.value.slice(0, i),
                        value,
                        ...field.value.slice(i + 1),
                    ]);
            });
        })}
      {renderFooter ? renderFooter(field.value, field.update) : null}
    </>);
}
export function FormSubmit(props) {
    const form = useContext(context);
    return <button onPress={form.submit} {...props}/>;
}
