import { useCallback, useState } from "react";

export default function useModalAction() {
  const [state, setState] = useState();
  return [
    state,
    useCallback((params) => {
      if (state?.params) state?.onClose();

      let resolve;
      const promise = new Promise((r) => {
        resolve = r;
      });
      setState({
        params,
        /**
         * @type {(response: import("../admin-one/types").DataActionResponse)=>void}
         */
        onClose: (response) => {
          setState(null);
          resolve(response);
        },
      });
      return promise;
    }, []),
  ];
}
