import capitalize from "../../utils/capitalize";
import sentenceCase from "../../utils/sentence_case";
import { FiPlus as Create, FiDelete as Delete, FiDatabase as DataObjectSharp, FiEdit as Edit, FiArrowUpRight as Launch, } from "react-icons/fi";
import React from "react";
import plural, { singular } from "../../utils/plural";
export const PRIMARY_COLOR = "#ff7722";
export const SORTABLE_FIELDS = [
    "boolean",
    "datetime",
    "string",
    "number",
    "reference",
];
export const UNIMPLEMENTED_PLACEHOLDER = (<i>No renderer found for this field</i>);
let _specs;
export function preloadSpecs(specs) {
    _specs = specs;
}
export function loadSpec(name) {
    return _specs[name];
}
export const actions = {
    create: {
        label: "Add {resource}",
        icon: Create,
    },
    update: {
        label: "Update {resource}",
        icon: Edit,
    },
    delete: {
        label: "Delete {resource}",
        icon: Delete,
        color: "error",
    },
    default: {
        label: "",
        icon: Launch,
    },
    resource: {
        label: "{resource}",
        icon: DataObjectSharp,
    },
};
export function renderIcon(icon, color, size) {
    const Icon = icon;
    return <Icon color={color} size={size}/>;
}
export const ADD_TRAILING_SLASH = true;
function replacePath(url, pathHandler) {
    let params;
    [url, params] = url?.split("?");
    return (pathHandler(url.replace(/\/$/, "")) +
        (ADD_TRAILING_SLASH ? "/" : "") +
        (params ? "?" + params : ""));
}
export function normalizeURL(url) {
    return replacePath(url, (path) => path);
}
export function getDetailURL(url, id) {
    return replacePath(url, (url) => url + "/" + id);
}
export function getActionURL(url, action) {
    return String(action).startsWith("/")
        ? action
        : replacePath(url, (url) => url + "/" + action);
}
export function getRouteForResource(spec) {
    return spec.restURL ? "/admin" + spec.restURL : null;
}
export function getLabelFromURL(label, url) {
    console.log({ label, url }, label
        ? plural(label)
        : url
            ?.toLowerCase()
            .replace(/(?:\/$|\/?\?.*$)/, "")
            .split("/")
            .pop()
            ?.replace(/[-_]/g, " "));
    return label
        ? plural(label)
        : url
            ?.toLowerCase()
            .replace(/(?:\/$|\/?\?.*$)/, "")
            .split("/")
            .pop()
            ?.replace(/[-_]/g, " ");
}
export function getSingularLabelFromURL(pluralLabel, _) {
    return pluralLabel ? singular(pluralLabel) : pluralLabel;
}
export function getPageTitle(spec) {
    return `Manage ${capitalize(spec.pluralLabel)}`;
}
export function getViewPageTitle(spec) {
    return `${capitalize(spec.label)} Details`;
}
export function getLabelFromKey(key) {
    return sentenceCase(key.replace(/([a-z])([A-Z])/g, "$1 $2")).replace(/[-_]/g, " ");
}
