import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import { createSpec } from "../../../component/admin-one/spec/createSpec";
import Table from "../../../component/table/Table";

function AdminAuthors() {
  const navigate = useNavigate();
  return (
    <div className="br__novel">
      <Table
        title="All Authors"
        config={{
          showSN: true,
          spec: createSpec({
            ...resources.users,
            columns: {
              ...resources.users.columns,
              num_novels: { type: "number", label: "No of novels" },
              is_active: {
                type: "boolean",
                label: "Status",
                renderTable(e) {
                  return e ? "Active" : "Inactive";
                },
              },
            },
          }),
          onSelectRow: {
            execute(data, spec) {
              navigate("/users/" + data.id);
            },
          },
          tableColumns: ["name", "email", "num_novels", "is_active"],
        }}
      />
    </div>
  );
}
export default AdminAuthors;
