import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import { createSpec } from "../../../component/admin-one/spec/createSpec";
import Table from "../../../component/table/Table";
import { APIActions } from "../../../component/admin-one/APIAction";

function AdminSubscriptions() {
  const navigate = useNavigate();
  return (
    <div className="br__novel">
      <Table
        title="Manage Withdrawals"
        config={{
          showSN: true,
          spec: createSpec({
            restURL: "/withdrawals",
            createAction: {
              label: "Add New",
              // Add Success Response to Data Action
              async execute(data, spec) {
                return APIActions.Create.execute(data, spec).then((e) => {
                  if (e.success) {
                    e.successResponse ??= "Subscription Plan Created";
                  }
                  return e;
                });
              },
            },
            actions: [APIActions.Update, APIActions.Delete],
            columns: {
              id: "number",
              email: "string",
              name: {
                type: "string",
                label: "Username",
              },
              amount: {
                type: "number",
              },
              description: "string",
              created_at: { type: "datetime", label: "Date", dateType: "date" },
              status: {
                // booleanStates: [yes, no, null=no]
                type: "boolean",
                renderTable(e) {
                  return e ? "Active" : "Inactive";
                },
              },
            },
          }),
          onSelectRow: {
            execute(data, spec) {
              navigate("/users/" + data.id);
            },
          },
          tableColumns: ["name", "email", "amount", "created_at", "status"],
        }}
      />
    </div>
  );
}
export default AdminSubscriptions;
