import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import Table from "../../../component/table/Table";
import { createSpec } from "../../../component/admin-one/spec/createSpec";

function AdminGenre() {
  const navigate = useNavigate();

  return (
    <div className="br__novel">
      <Table
        title="Manage Genres"
        // There should be a phone number type
        // Form might want to make use of redux for useField and formContext
        config={{
          listActions: [],
          showSN: true,
          spec: createSpec({
            restURL: "/genres",
            columns: {
              id: "string",
              name: "string",
            },
          }),
        }}
      />
    </div>
  );
}

export default AdminGenre;
