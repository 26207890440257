import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import Table from "../../../component/table/Table";
import { createSpec } from "../../../component/admin-one/spec/createSpec";

function AdminCoins() {
  const navigate = useNavigate();

  return (
    <div className="br__novel">
      <Table
        title="Manage Coins"
        // There should be a phone number type
        // Form might want to make use of redux for useField and formContext
        config={{
          listActions: [],
          showSN: true,
          spec: createSpec({
            restURL: "/coin-options?",
            columns: {
              id: "string",
              num_coins: "string",
              price: "string",
              bonus: "string",
              status: {
                type: "boolean",
                renderTable({ data }) {
                  return data ? "Active" : "Inactive";
                },
              },
            },
          }),
          tableColumns: ["num_coins", "price", "bonus", "status"],
        }}
      />
    </div>
  );
}

export default AdminCoins;
