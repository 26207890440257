import { APIActions } from "../APIAction";
import { actions, getLabelFromKey, getLabelFromURL, getSingularLabelFromURL, loadSpec, SORTABLE_FIELDS, } from "../conventions";
import { createPermissionSpec } from "./permissions";
import { getRenderer } from "./registerRenderer";
import { defaultValidator } from "./validation";
const None = {};
const renderCell = (props) => {
    return getRenderer(props.spec, "table")(props);
};
const renderForm = (props) => {
    return getRenderer(props.spec, "form")(props);
};
const renderShow = (props) => {
    return getRenderer(props.spec, "view")(props);
};
const renderInline = (props) => {
    return getRenderer(props.spec, "inline")(props);
};
export function getDefaults(spec) {
    return Object.values(spec.columns).reduce((a, e) => {
        a[e.key] = e.default;
        return a;
    }, {});
}
export function inferFilterType(res) {
    switch (res.type) {
        case "string":
        case "boolean":
        case "reference":
            return res;
        case "datetime":
        case "number":
            return createColumnSpec({
                type: "range",
                rangeType: res,
                canCreate: res.canCreate,
                canView: res.canView,
            }, res.key);
        case "list":
            return res.listType;
        case "range":
            return res.rangeType;
        case "file":
        case "image":
            return createColumnSpec({
                type: "boolean",
                canCreate: res.canCreate,
                canView: res.canView,
            }, res.key);
        default:
            console.log(res);
            throw new Error("No filter type defined for " + res.type);
    }
}
export function createSpec(e, { withFilters = !!e.restURL } = {}) {
    const pluralLabel = e.pluralLabel ?? getLabelFromURL(e.label, e.restURL);
    const filters = [];
    const columns = Object.entries(e.columns).reduce((acc, [f, val]) => {
        const res = createColumnSpec(val, f);
        if (res.filterable) {
            if (typeof val === "string" || !val.filterSpec) {
                if (withFilters)
                    filters.push(inferFilterType(res));
            }
            else if (Array.isArray(val.filterSpec)) {
                for (const item of val.filterSpec) {
                    filters.push(createColumnSpec(item.type, item.key));
                }
            }
            else
                filters.push(createColumnSpec(val.filterSpec, f));
        }
        acc[f] = res;
        return acc;
    }, {});
    const specs = Object.values(columns);
    const pk = specs.find((e) => e.stringType === "pk" || e.numberType === "pk")?.key ??
        "id";
    return {
        ...e,
        filterSpec: filters.length === 0
            ? undefined
            : {
                columns: filters.reduce((acc, res) => {
                    acc[res.key] = res;
                    return acc;
                }, {}),
            },
        tableColumns: specs.filter((e) => e.showOnTable).map((e) => e.key),
        icon: e.icon === undefined ? actions.resource.icon : e.icon,
        createAction: e.createAction ?? APIActions.Create,
        updateAction: e.updateAction ?? APIActions.Update,
        meta: e.meta ?? None,
        pk,
        label: e.label ?? getSingularLabelFromURL(pluralLabel, e.restURL),
        pluralLabel,
        urlLookup: e.urlLookup ?? pk,
        ...createPermissionSpec(e),
        columns,
        slots: inferSlots(e, specs, pk),
        actions: e.actions ?? (e.readOnly ? [] : [APIActions.Delete]),
    };
}
function inferSlots(e, specs, pk) {
    const usedSlots = e.slots
        ? Object.values(e.slots).concat(e.slots.others ?? [])
        : [];
    const inferredSlots = {
        avatar: e.slots?.avatar ??
            specs.find((e) => !usedSlots.includes(e.key) &&
                e.type === "image" &&
                e.imageType === "avatar")?.key,
        content: e.slots?.content ??
            specs.find((e) => !usedSlots.includes(e.key) &&
                e.type === "string" &&
                e.stringType === "longtext")?.key,
        image: e.slots?.image ??
            specs.find((e) => !usedSlots.includes(e.key) &&
                e.type === "image" &&
                e.imageType === "banner")?.key,
        title: e.slots?.title ??
            specs.find((e) => !usedSlots.includes(e.key) &&
                e.type === "string" &&
                e.stringType !== "longtext" &&
                e.stringType !== "pk")?.key,
        timestamp: e.slots?.timestamp ??
            specs.find((e) => !usedSlots.includes(e.key) && e.type === "datetime")
                ?.key,
        rating: e.slots?.rating ??
            specs.find((e) => !usedSlots.includes(e.key) &&
                e.type === "number" &&
                e.numberType === "rating")?.key,
        ...e.slots,
    };
    const inferredSlotKeys = Object.values(inferredSlots);
    return {
        others: inferredSlots.others ??
            specs
                .filter((e) => !inferredSlotKeys.includes(e.key) && e.key !== pk)
                .map((e) => e.key),
        ...inferredSlots,
    };
}
function defaultSelect(data) {
    return data?.[this.key];
}
function defaultSelectMeta() {
    return this.meta ?? None;
}
function getter(e) {
    return () => e;
}
function loader(spec) {
    let cached;
    return () => cached
        ? cached
        : (cached = spec.extends
            ? { ...loadSpec(spec.base), ...spec.extends }
            : loadSpec(spec.base));
}
function createColumnSpec(val, f) {
    if (typeof val == "string") {
        val = {
            type: val,
        };
    }
    let listType = val.type === "list" ? val.listType : undefined;
    if (listType) {
        listType = createColumnSpec(listType, "item");
    }
    let rangeType = val.type === "range" ? val.rangeType ?? "number" : undefined;
    if (rangeType) {
        rangeType = createColumnSpec(rangeType, "item");
    }
    const rawReferenceSpec = val.referenceSpec;
    const completions = val.completions;
    const referenceMode = val.type === "reference"
        ? val.referenceMode ??
            (!completions
                ? typeof rawReferenceSpec === "function" ||
                    rawReferenceSpec?.restURL ||
                    rawReferenceSpec?.extends?.restURL
                    ? "api-relation"
                    : "object-relation"
                : "id-relation")
        : undefined;
    const referenceSpec = rawReferenceSpec
        ? typeof rawReferenceSpec === "function"
            ? rawReferenceSpec
            : "base" in rawReferenceSpec
                ? loader(rawReferenceSpec)
                : getter(createSpec(rawReferenceSpec))
        : undefined;
    const stringType = val.type && val.type !== "string"
        ? undefined
        : val.stringType ?? (f === "id" ? "pk" : "text");
    return {
        key: f,
        label: val.label ?? getLabelFromKey(f),
        type: val.type || "string",
        stringType,
        numberType: val.type === "number" ? val.numberType ?? "integer" : undefined,
        dateType: val.type === "datetime" ? val.dateType ?? "datetime" : undefined,
        imageType: val.type === "image" ? val.imageType ?? "banner" : undefined,
        boolType: val.type === "boolean" ? val.boolType ?? "checkbox" : undefined,
        listType: listType,
        rangeType: rangeType,
        referenceMode,
        referenceSpec,
        default: val.default,
        select: val.select || defaultSelect,
        options: val.options ?? undefined,
        completions: completions
            ? typeof completions === "function"
                ? completions
                : getter(createSpec(completions, { withFilters: false }))
            : undefined,
        ["meta"]: val.meta || undefined,
        selectMeta: val.selectMeta ?? defaultSelectMeta,
        validate: val.validate ?? defaultValidator,
        validationRules: val.validationRules ?? undefined,
        required: val.required ?? true,
        group: val.group ?? "default",
        showOnTable: val.showOnTable ?? true,
        searchable: (val.type === "string" && stringType !== "pk") ||
            (val.type === "list" && !!listType?.searchable),
        filterable: (val.type === "string" && stringType !== "pk") ||
            (val.type === "list" && !!listType?.searchable),
        sortable: val.sortable ?? (SORTABLE_FIELDS.includes(val.type) ? "both" : false),
        renderTable: val.renderTable ?? renderCell,
        renderForm: val.renderForm ?? renderForm,
        renderShow: val.renderShow ?? renderShow,
        renderInline: val.renderInline ?? renderInline,
        ...createPermissionSpec(stringType === "pk" ? { readOnly: true, ...val } : val),
    };
}
