import { resources } from "../../../api/resources";
import { ManageNovels } from "../admin-novels/AdminNovel";
import "./admin-novels-new.css";

function AdminNovelsNew() {
  return (
    <ManageNovels
      columns={[
        "id",
        "title",
        "author",
        "genres",
        "num_chapters",
        "num_views",
        "contract_status",
        "editor",
      ]}
      title="Approved Novels"
      spec={{
        ...resources.novels,
        restURL: "novels/admin?listing=new",
      }}
    />
  );
}
export default AdminNovelsNew;
