import { useRef, useState } from "react";
import Form from "../forms/AppForm";
import { ClipLoader } from "react-spinners";

/**
 * @template {string} T
 * @param {object} param0
 * @param {import("../admin-one/types").DataSpec<T>} param0.spec
 * @param {number|string} [param0.id]
 * @param {object} [param0.initialData]
 * @param {import("../admin-one/types").DataAction} [param0.action]
 * @param {boolean} [param0.closeOnFail]
 * @param {(response: import("../admin-one/types").DataActionResponse)=>void} [param0.onSubmit]
 * @param {T[]} [param0.columns]
 * @returns
 */
export default function ActionModal({
  spec,
  id,
  initialData,
  action,
  closeOnFail,
  onSubmit,
  columns,
}) {
  id ??= spec.columns[spec.pk]?.select(initialData);
  action ??= id ? spec.updateAction : spec.createAction;
  onSubmit ??= (response) => {
    alert(response.successResponse || response.error || "Done");
  };
  columns ??= Object.keys(spec.columns);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  return (
    <div className="admin__modal" style={{ width: "100%" }}>
      <h1>{action.label.replace("{resource}", spec.label)}</h1>
      {errorMessage && (
        <p
          style={{
            color: "red",
          }}
        >
          {errorMessage}
        </p>
      )}
      <Form
        formRef={ref}
        initialValue={initialData}
        onError={(e) => {
          console.log({ e });
          setErrorMessage(e.error);
        }}
        onSubmit={async (data) => {
          setLoading(true);
          try {
            await action
              .execute(data, spec)
              .then((res) => {
                if (!closeOnFail && res && res.success === false) {
                  ref.current.setError({
                    error: res.error || res.rawResponse?.error,
                    errors: res.rawResponse?.detail,
                  });
                } else onSubmit(res);
              })
              .catch((e) => {
                ref.current.setError({
                  error: e.response?.data?.error || e.message,
                  errors: e.response?.data?.detail,
                });
              });
          } finally {
            setLoading(false);
          }
        }}
      >
        <form
          className="admin__modal__form"
          onSubmit={(e) => {
            e.preventDefault();
            ref.current?.submit();
          }}
          style={{
            maxHeight: "calc(100vh - 200px)",
            justifyContent: "start",
            overflowY: "auto",
          }}
        >
          {columns.map((e) =>
            spec.columns[e].renderForm({ spec: spec.columns[e] })
          )}
          <button>
            {loading ? (
              <ClipLoader size={20} />
            ) : (
              action.label.replace("{resource}", spec.label)
            )}
          </button>
        </form>
      </Form>
    </div>
  );
}
