export function hasPermission(e, permissions) {
    return permissions
        ? !permissions.length || permissions.some((f) => e.permissions.includes(f))
        : true;
}
export const Permissions = {
    ALWAYS: defaultAuthorizationHandler,
    NEVER: defaultForbiddenHandler,
};
function defaultAuthorizationHandler() {
    return true;
}
function defaultForbiddenHandler() {
    return false;
}
function defaultCreateHandler(user) {
    return hasPermission(user, this.createPermissions);
}
function defaultUpdateHandler(user) {
    return hasPermission(user, this.updatePermissions);
}
function defaultListHandler(user) {
    return hasPermission(user, this.listPermissions);
}
function defaultViewHandler(user) {
    return hasPermission(user, this.viewPermissions);
}
export function createPermissionSpec(val) {
    return {
        createPermissions: val.createPermissions || val.writePermissions,
        canCreate: val.canCreate && val.canCreate !== defaultCreateHandler
            ? val.canCreate
            : val.readOnly === true || val.allowCreate === false
                ? Permissions.NEVER
                : !(val.createPermissions || val.writePermissions)?.length
                    ? Permissions.ALWAYS
                    : defaultCreateHandler,
        updatePermissions: val.updatePermissions || val.writePermissions,
        canUpdate: val.canUpdate && val.canUpdate !== defaultUpdateHandler
            ? val.canUpdate
            : val.readOnly === true || val.allowUpdate === false
                ? Permissions.NEVER
                : !(val.updatePermissions || val.writePermissions)?.length
                    ? Permissions.ALWAYS
                    : defaultCreateHandler,
        listPermissions: val.listPermissions || val.readPermissions,
        canList: val.canList && val.canList !== defaultListHandler
            ? val.canList
            : !(val.listPermissions || val.readPermissions)?.length
                ? Permissions.ALWAYS
                : defaultListHandler,
        viewPermissions: val.viewPermissions || val.readPermissions,
        canView: val.canView && val.canView !== defaultViewHandler
            ? val.canView
            : !(val.viewPermissions || val.readPermissions)?.length
                ? Permissions.ALWAYS
                : defaultViewHandler,
    };
}
