import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import { createSpec } from "../../../component/admin-one/spec/createSpec";
import Table from "../../../component/table/Table";
import "./admin-users.css";

function AdminUsers() {
  const navigate = useNavigate();
  return (
    <div className="br__novel">
      <Table
        title="Users"
        config={{
          showSN: true,
          spec: createSpec({
            ...resources.users,
            columns: {
              ...resources.users.columns,
              subscribed: {
                type: "boolean",
                select(data) {
                  return data.subscription_class != null;
                },
              },
              subscription_end_date: {
                type: "datetime",
                label: "Sub Validity",
              },
            },
          }),
          onSelectRow: {
            execute(data, spec) {
              navigate("/users/" + data.id);
            },
          },
          tableColumns: [
            "name",
            "id",
            "email",
            "subscribed",
            "last_login",
            "subscription_end_date",
            "status",
          ],
        }}
      />
    </div>
  );
}
export default AdminUsers;
