import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import { createSpec } from "../../../component/admin-one/spec/createSpec";
import Table from "../../../component/table/Table";
import { APIActions } from "../../../component/admin-one/APIAction";

function AdminSubscriptionPlans() {
  const navigate = useNavigate();
  return (
    <div className="br__novel">
      <Table
        title="All Subscription Plans"
        config={{
          showSN: true,
          spec: createSpec({
            restURL: "/subscription-plans",
            createAction: {
              label: "Add New",
              // Add Success Response to Data Action
              async execute(data, spec) {
                return APIActions.Create.execute(data, spec).then((e) => {
                  if (e.success) {
                    e.successResponse ??= "Subscription Plan Created";
                  }
                  return e;
                });
              },
            },
            actions: [APIActions.Update, APIActions.Delete],
            columns: {
              id: "number",
              name: {
                type: "string",
              },
              amount: {
                type: "number",
                label: "Price",
              },
              description: "string",
              duration: {
                type: "string",
                // Should options set default -> yes if required
                default: "24",
                options: {
                  24: "1 day",
                  168: "1 week",
                  336: "2 weeks",
                  744: "1 month",
                  1464: "2 months",
                  2208: "3 months",
                  4392: "6 months",
                  8784: "1 year",
                },
              },
              status: {
                // booleanStates: [yes, no, null=no]
                type: "boolean",
                renderTable(e) {
                  return e ? "Active" : "Inactive";
                },
              },
            },
          }),
          onSelectRow: {
            execute(data, spec) {
              navigate("/users/" + data.id);
            },
          },
          tableColumns: ["name", "amount", "duration", "status"],
        }}
      />
    </div>
  );
}
export default AdminSubscriptionPlans;
