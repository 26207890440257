import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import Table from "../../../component/table/Table";

function AdminAdmins() {
  const navigate = useNavigate();

  return (
    <div className="br__novel">
      <Table
        title="Manage Admins"
        // There should be a phone number type
        // Form might want to make use of redux for useField and formContext
        config={{
          listActions: [],
          showSN: true,
          label: "Admin",
          spec: {
            ...resources.staff,
            actions: [],
          },
          tableColumns: ["name", "email", "phone_number", "is_active"],
        }}
      />
    </div>
  );
}

export default AdminAdmins;
