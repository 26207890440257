import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import Table from "../../../component/table/Table";
import "./admin-review.css";

function AdminReview() {
  const navigate = useNavigate();

  return (
    <div className="br__novel">
      <Table
        title="Chapters Review"
        config={{
          listActions: [],
          showSN: true,
          showExport: true,
          spec: {
            ...resources.chapters,
            actions: [],
          },
          tableColumns: ["novel_title", "chapter", "created_at"],
        }}
      />
    </div>
  );
}

export default AdminReview;
