import { useNavigate } from "react-router-dom";
import { resources } from "../../../api/resources";
import Table from "../../../component/table/Table";
import { createSpec } from "../../../component/admin-one/spec/createSpec";

function AdminContracts() {
  const navigate = useNavigate();

  return (
    <div className="br__novel">
      <Table
        title="New Contracts"
        // There should be a phone number type
        // Form might want to make use of redux for useField and formContext
        config={{
          listActions: [],
          showSN: true,
          spec: createSpec({
            restURL: "/contracts?",
            columns: {
              id: {
                type: "string",
                stringType: "pk",
                readOnly: true,
                select(e) {
                  return e?.novel.id;
                },
              },
              novel: {
                type: "reference",
                referenceSpec: resources.novels,
              },
              user: {
                type: "reference",
                referenceSpec: resources.users,
              },
              title: {
                type: "string",
                readOnly: true,
                select(e) {
                  return e?.novel.title;
                },
              },
              author: {
                type: "string",
                readOnly: true,
                select(e) {
                  return e?.novel.author.name;
                },
              },
              chapters: {
                type: "string",
                readOnly: true,
                select(e) {
                  return e?.novel.num_chapters;
                },
              },
              category: {
                type: "string",
                readOnly: true,
                select(e) {
                  return e?.novel.genres?.[0];
                },
              },
              status: "string",
              email: "string",
              full_name: "string",
              pen_name: "string",
              facebook_handle: "string",
              document_type: "string",
              document_number: "string",
              front_image: "string",
              front_image_width: "string",
              front_image_height: "string",
              back_image: "string",
              back_image_width: "string",
              back_image_height: "string",
              country_of_residence: "string",
              phone_number: "string",
              permanent_address: "string",
              city: "string",
              state: "string",
              postal_code: "string",
              above21: "string",
              book_title: "string",
              contract_type: "string",
              synopsis: "string",
              outline: "string",
              platform_link: "string",
              planned_length: "string",
              avg_chapter_words: "string",
              update_frequency: "string",
              push_lines: "string",
            },
          }),
          tableColumns: ["title", "author", "category", "chapters"],
        }}
      />
    </div>
  );
}

export default AdminContracts;
