import { useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BiExport, BiFilter, BiLoaderCircle, BiPlus } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";
import { APIActions } from "../admin-one/APIAction";
import APIParamsProvider from "../admin-one/views/APIParams";
import { getDefaults } from "../admin-one/spec/createSpec";
import Modal from "../modal/Modal";
import Loading from "../splash/loading/Loading";
import toastManager from "../toast/ToasterManager";
import ActionModal from "./ActionModal";
import Pagination from "./Pagination";
import "./setup-renderers";
import "./table.css";
import useModalAction from "./useModalAction";
import useQuery from "./useQuery";

/**
 * @template T
 * @param {object} param0
 * @param {import("./TableConfig").TableConfig<T>} param0.config
 * @param {number} param0.pageSize
 * @returns
 */
export default function Table({ title, config, pageSize = 10 }) {
  const [page, setPage] = useState(1);
  const [filters, setFilter] = useState({});
  //   const { permissions } = useSelector((state) => state.permissions);

  const url = useMemo(() => {
    let baseURL = config.spec.restURL;
    const parts = { ...filters };
    if (page > 1) {
      parts["limit"] = pageSize;
      parts["offset"] = (page - 1) * pageSize;
    }
    if (Object.keys(parts).length === 0) return baseURL;
    let separator = baseURL.includes("?") ? "&" : "?";
    for (let i in parts) {
      baseURL += separator + i + "=" + encodeURIComponent(parts[i]);
      if (separator === "?") separator = "&";
    }
    return baseURL;
  }, [config.spec.restURL, filters, page, pageSize]);

  const {
    fetchError,
    loading,
    reload,
    count,
    response: { results: data = [] } = {},
  } = useQuery(url);
  const lastItemRef = null;

  const hasBulkActions = config.bulkActions && config.bulkActions.length > 0;

  const [selected, setSelected] = useState([]);
  const columns = (
    config.tableColumns ?? Object.keys(config.spec.columns)
  ).filter((e) => config.spec.columns[e]);
  const [modals, setModals] = useState([]);
  const openModal = (props) => {
    setModals((modals) => modals.concat(props));
  };
  const closeModal = () => {
    setModals((modals) => modals.slice(0, -1));
  };

  const modalOpen = modals[modals.length - 1];
  const [formAction, startFormAction] = useModalAction();

  /**
   * @param {import("../admin-one/types").ColumnSpec} spec
   * @param {any} data
   * @param {meta} data
   */

  const [isActionDropdown, setIsActionDropdown] = useState(null);

  const [numPromises, setNumPromises] = useState(0);
  const _await = (promise) => {
    if (!promise) return;
    setNumPromises((numPromises) => numPromises + 1);
    promise.finally(() => setNumPromises((numPromises) => numPromises - 1));
  };
  /**
   *
   * @param {import("../admin-one/types").DataAction} action
   * @param {any} data
   */
  const execute = async (
    action,
    data,
    form = action.form,
    handleConfirmationText
  ) => {
    if (action.confirmationText && handleConfirmationText !== false) {
      openModal({
        text: action.confirmationText,
        confirmText: action.label.replace("{resource}", config.spec.label),
        handler() {
          return execute(action, data, form, false);
        },
      });
    } else {
      if (form && form !== true) {
        setIsActionDropdown(null);
        _await(
          startFormAction({
            spec: form,
            action,
            data: {
              ...getDefaults(form),
              ...(action.preprocess ? action.preprocess(data) : data),
            },
          }).then((e) => {
            if (!e) return;
            toastManager.addToast({
              message: e.success
                ? e.successResponse ?? "Success"
                : e.error ?? "An error occured",
              type: e.success ? "success" : "error",
            });
            setIsActionDropdown(null);
            if (e.shouldReload) {
              reload();
            }
          })
        );
      } else {
        _await(
          action.execute(data, config.spec)?.then((e) => {
            if (!e) return;
            toastManager.addToast({
              message: e.success ? e.successResponse : e.error,
              type: e.success ? "success" : "error",
            });
            setIsActionDropdown(null);
            if (e.shouldReload) {
              reload();
            }
          })
        );
      }
    }
  };
  return (
    <APIParamsProvider spec={config.spec}>
      <div
        onClick={() => {
          if (isActionDropdown) {
            setIsActionDropdown(null);
          }
        }}
        style={{
          position: "relative",
        }}
      >
        <section className="br__novel__sc__one">
          <h1>{title}</h1>
          <span className="br__novel__sc__one__span"></span>
        </section>
        {/* Flter and Create action */}
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            display: "flex",
            gap: "1em",
            undefined,
          }}
        >
          <button className="one-table-button one-table-button-primary-outlined">
            <BiFilter
              color="inherit"
              size={20}
              style={{ marginRight: "0.5em" }}
            />{" "}
            <div>Filter</div>
          </button>

          {config.listActions?.map((action) => (
            <button
              className="one-table-button one-table-button-primary"
              onClick={() => {
                execute(action, null);
              }}
            >
              <BiPlus
                color="inherit"
                size={20}
                style={{ marginRight: "0.5em" }}
              />{" "}
              <div>{action.label.replace("{resource}", config.spec.label)}</div>
            </button>
          ))}
          <button
            className="one-table-button one-table-button-primary"
            onClick={() => {
              execute(
                config.spec.createAction,
                null,
                config.spec.createAction.form || config.spec
              );
            }}
          >
            <BiPlus
              color="inherit"
              size={20}
              style={{ marginRight: "0.5em" }}
            />{" "}
            <div>
              {config.spec.createAction.label.replace(
                "{resource}",
                config.spec.label
              )}
            </div>
          </button>
        </div>
        {/* BUlk Export and Buld Actions */}
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            gap: "1em",
          }}
        >
          {config.showExport ? (
            <button className="one-table-button">
              <BiExport
                color="inherit"
                size={20}
                style={{ marginRight: "0.5em" }}
              />{" "}
              <div>Export</div>
            </button>
          ) : null}
          {hasBulkActions ? (
            <button className="one-table-button">
              <div>Bulk Action</div>
            </button>
          ) : null}
        </div>

        {/* Table */}
        <div
          style={{
            marginTop: "1em",
            minHeight: pageSize * 44 + "px",
            width: "100%",
            overflow: "auto",
            backgroundColor: "#f3f3f3",
            borderRadius: "0.5em",
          }}
        >
          <table style={{ minWidth: "100%" }}>
            <thead>
              <TableHeader
                hasBulkActions={hasBulkActions}
                showSN={config.showSN}
                columns={columns}
                spec={config.spec}
              />
            </thead>
            <tbody>
              {loading || fetchError ? (
                <tr>
                  <td
                    colSpan={
                      columns.length +
                      (hasBulkActions ? 1 : 0) +
                      /*hasActions*/ (config.spec.actions &&
                      config.spec.actions.length > 0
                        ? 1
                        : 0) +
                      (config.showSN ? 1 : 0)
                    }
                    style={{ textAlign: "center" }}
                  >
                    {loading ? (
                      <div className="loading-indicator">
                        <Loading />
                        <i>"Loading..."</i>
                      </div>
                    ) : (
                      <span style={{ color: "red" }}>{fetchError}</span>
                    )}
                  </td>
                </tr>
              ) : null}
              {fetchError
                ? null
                : data?.map((e, i) => (
                    // TODO- Error Boundary
                    <ErrorBoundary
                      key={
                        config.spec.columns[config.spec.pk]?.select(e) ??
                        "item+" + i
                      }
                      fallback={
                        <tr>
                          <td
                            colSpan={
                              columns.length +
                              (hasBulkActions ? 1 : 0) +
                              /*hasActions*/ (config.spec.actions &&
                              config.spec.actions.length > 0
                                ? 1
                                : 0) +
                              (config.showSN ? 1 : 0)
                            }
                            style={{ textAlign: "center" }}
                          >
                            An error occured while rendering this row.
                          </td>
                        </tr>
                      }
                    >
                      <TableRow
                        data={e}
                        rowRef={i === data.length - 1 ? lastItemRef : null}
                        spec={config.spec}
                        index={(page - 1) * pageSize + i}
                        showSN={config.showSN}
                        columns={columns}
                        execute={execute}
                        onSelectRow={modalOpen ? null : config.onSelectRow}
                        isActionDropdown={isActionDropdown}
                        setIsActionDropdown={setIsActionDropdown}
                        selected={hasBulkActions ? selected : null}
                        setSelected={hasBulkActions ? setSelected : null}
                      />
                    </ErrorBoundary>
                  ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginTop: "2em",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontSize: 16, fontWeight: 500 }}>
            Showing {(page - 1) * pageSize + 1} to{" "}
            {Math.min(count || 0, page * pageSize)} of {count ?? "??"}
          </span>

          <Pagination
            page={page}
            count={count}
            pageSize={pageSize}
            setPage={setPage}
          />
        </div>
        {/* Confirmation Modal */}
        <Modal isOpen={modalOpen} onClose={closeModal}>
          <div className="admin__modal">
            <h1>{modalOpen?.text}</h1>
            <div
              style={{
                flexDirection: "row",
                justifyContent: "end",
                gap: "1em",
                display: "flex",
                width: "100%",
              }}
            >
              <button onClick={closeModal} className="one-table-button">
                Cancel
              </button>
              <button
                onClick={() => {
                  closeModal();
                  modalOpen.handler();
                }}
                className="one-table-button=primary"
              >
                {modalOpen?.confirmText}
              </button>
            </div>
          </div>
        </Modal>

        <Modal isOpen={!!formAction} onClose={() => formAction?.onClose()}>
          <ErrorBoundary
            onError={(e, i) => {
              console.error(e);
              console.error(i.componentStack);
              toastManager.addToast({
                message: e.message ?? "An error occured",
                type: "error",
              });
            }}
            fallbackRender={({ resetErrorBoundary, e }) => {
              if (formAction) {
                formAction?.onClose();
                resetErrorBoundary();
              }
              return null;
            }}
          >
            <ActionModal
              spec={formAction?.params?.spec}
              action={formAction?.params?.action}
              initialData={formAction?.params?.data}
              onSubmit={formAction?.onClose}
            />
          </ErrorBoundary>
        </Modal>
        {/* Full screen loader  */}
        {numPromises > 0 ? (
          <div
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#77777777",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BiLoaderCircle />
          </div>
        ) : null}
      </div>
    </APIParamsProvider>
  );
}

function TableHeader({ hasBulkActions, showSN, columns, spec }) {
  const hasActions = spec.actions && spec.actions.length > 0;
  return (
    <tr>
      {hasBulkActions ? (
        <th style={{ borderBottom: "2px solid white" }}></th>
      ) : null}
      {showSN ? (
        <th
          style={{
            textAlign: "start",
            fontSize: 14,
            padding: "0.5em 0.5em",
            fontWeight: 600,
            borderBottom: "2px solid white",
          }}
        >
          S/N
        </th>
      ) : null}
      {columns.map((e) => (
        <th
          key={e}
          style={{
            textAlign: "start",
            fontSize: 14,
            padding: "0.5em 0.5em",
            fontWeight: 600,
            borderBottom: "2px solid white",
          }}
        >
          {spec.columns[e].label}
        </th>
      ))}
      {hasActions ? (
        <th
          style={{
            textAlign: "start",
            fontSize: 14,
            padding: "0.5em 0.5em",
            fontWeight: 600,
            borderBottom: "2px solid white",
          }}
        >
          Action
        </th>
      ) : null}
    </tr>
  );
}

function TableRow({
  data,
  spec,
  rowRef,
  index,
  showSN,
  execute,
  columns,
  onSelectRow,
  selected,
  setSelected,
  isActionDropdown,
  setIsActionDropdown,
}) {
  const key = spec.columns[spec.pk].select(data);
  return (
    <tr
      key={key}
      ref={rowRef}
      onClick={(e) => {
        e.stopPropagation();
        if (isActionDropdown) {
          return setIsActionDropdown(null);
        }
        if (onSelectRow) {
          execute(onSelectRow, data);
        }
      }}
    >
      {setSelected ? (
        <td
          style={{
            paddingLeft: "0.5em",
            paddingRight: "0.5em",
            borderBottom: "1px solid white",
          }}
          onClick={(e) => {
            if (isActionDropdown) {
              return setIsActionDropdown(null);
            } else {
              e.stopPropagation();
            }
          }}
        >
          <input
            type="checkbox"
            checked={selected.includes(key)}
            onChange={() => {
              if (selected.includes(key)) {
                setSelected(selected.filter((e) => e !== key));
              } else {
                setSelected([...selected, key]);
              }
            }}
          />
        </td>
      ) : null}
      {showSN ? (
        <td
          className="admin-table-cell"
          style={{ borderBottom: "1px solid white" }}
        >
          {index + 1}
        </td>
      ) : null}
      {columns.map((e) => (
        <TableCell
          key={e}
          spec={spec.columns[e]}
          data={spec.columns[e].select(data)}
          meta={spec.columns[e].selectMeta(data)}
        />
      ))}
      {spec.actions.length ? (
        <td
          style={{
            paddingLeft: "0.5em",
            paddingRight: "0.5em",
            width: "3em",
            textAlign: "center",
            borderBottom: "1px solid white",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <button
              onClick={() =>
                isActionDropdown === key
                  ? setIsActionDropdown(null)
                  : setIsActionDropdown(key)
              }
              style={{
                backgroundColor: "transparent",
                color: "black",
                fontWeight: "bold",
                fontSize: 20,
                padding: 0,
                margin: 0,
              }}
            >
              ...
            </button>
            {isActionDropdown === key && (
              <div className="admin-action-menu" style={{ padding: "0.5em" }}>
                <ul>
                  {spec.actions.map((e) => {
                    const onClick = async () => {
                      execute(e, data, e === APIActions.Update ? spec : e.form);
                    };
                    return (
                      <li onClick={onClick} style={{ padding: "0.5em" }}>
                        {e.label.replace("{resource}", spec.label)}{" "}
                        {e.form ? <FaArrowRight /> : null}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </td>
      ) : null}
    </tr>
  );
}

function TableCell({ spec, data, meta }) {
  return (
    <td
      className="admin-table-cell"
      style={{ borderBottom: "1px solid white" }}
    >
      {spec.renderTable({
        data,
        meta,
        spec,
        user: null,
      })}
    </td>
  );
}
