import { useNavigate } from "react-router-dom";
import { createSpec } from "../../../component/admin-one/spec/createSpec";
import Table from "../../../component/table/Table";
import { useGetTagCategories } from "../../../redux/actions/tagsActions";
import useQuery from "../../../component/table/useQuery";
import { APIActions } from "../../../component/admin-one/APIAction";

const tagCategorySpec = createSpec({
  label: "Tag Category",
  restURL: "tag-categories",
  columns: {
    id: "string",
    name: "string",
  },
});
function AdminTags() {
  const navigate = useNavigate();
  const categories = useQuery(tagCategorySpec.restURL);

  return (
    <div className="br__novel">
      <Table
        title="Tags"
        // There should be a phone number type
        // Form might want to make use of redux for useField and formContext
        // Remove canCreate, canUpdate and replace with createAction.canExecute(user,spec)
        config={{
          listActions: [
            Object.assign(Object.create(tagCategorySpec.createAction), {
              label: "Add Category",
              form: tagCategorySpec,
              execute(...args) {
                try {
                  return tagCategorySpec.createAction.execute(...args);
                } finally {
                  categories.reload();
                }
              },
            }),
          ],
          showSN: true,
          spec: createSpec({
            restURL: "/tags",

            columns: {
              id: "string",
              name: "string",
              category: {
                type: "reference",
                referenceSpec: tagCategorySpec,
                renderTable({ data: e }) {
                  return e
                    ? categories.loading
                      ? "Loading..."
                      : categories.response
                      ? categories.response.results.find((f) => f.id === e)
                          ?.name || "Deleted category"
                      : "Category(" + e + ")"
                    : "No Category";
                },
              },
            },
          }),
          tableColumns: ["name", "category"],
        }}
      />
    </div>
  );
}

export default AdminTags;
