import React, { createContext, useContext } from "react";
const ctx = createContext(null);
export default function APIParamsProvider({ spec, data, children, }) {
    const table = useContext(ctx);
    return (<ctx.Provider value={data ? { ...table, [spec.urlLookup]: data[spec.pk] } : table}>
      {children}
    </ctx.Provider>);
}
export const useResolvedAPIRoute = (route) => {
    const m = useContext(ctx);
    if (!route || !m)
        return route;
    return Object.entries(m).reduce((a, e) => {
        return a.replace("{" + e[0] + "}", e[1]);
    }, route);
};
