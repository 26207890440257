import { createSpec } from "../component/admin-one/spec/createSpec";

const users = createSpec({
  restURL: "/users",
  columns: {
    id: {
      type: "number",
      numberType: "pk",
      label: "User ID",
    },
    name: {
      type: "string",
      readOnly: true,
      select(data) {
        return data.first_name + " " + data.last_name;
      },
    },
    photo_url: {
      type: "image",
      imageType: "avatar",
    },

    email: {
      type: "string",
      stringType: "email",
      label: "Email Address",
    },
    phone_number: {
      type: "string",
    },
    pen_name: "string",
    first_name: "string",
    last_name: "string",
    email_verified: "boolean",
    is_active: "boolean",
    last_login: { type: "datetime", label: "Last Activity" },
  },
});
const staff = createSpec({
  ...users,
  restURL: "/staff",
  label: "Editor",
  pluralLabel: "Editors",
});

const tags = createSpec({
  restURL: "/tags",
  columns: {
    id: "number",
    name: "string",
  },
});

const person = createSpec({
  restURL: "/users",
  columns: {
    id: "string",
    name: {
      type: "string",
      select(data) {
        return data ? data.name || data.pen_name : undefined;
      },
    },
    photo_url: {
      type: "image",
      imageType: "avatar",
    },
  },
});
const genres = createSpec({
  restURL: "/genres",
  columns: {
    id: "number",
    name: "string",
  },
});

const chapters = createSpec({
  restURL: "/chapters",
  label: "Chapter",
  urlLookup: "chapterId",
  columns: {
    id: {
      type: "string",
      readOnly: true,
      select(data) {
        return data.chapter;
      },
      showOnTable: false,
    },
    chapter: {
      type: "number",
      numberType: "decimal",
      selectMeta(data) {
        return data;
      },
      renderTable({ data, meta }) {
        return data?.replace(/\.?0?0$/, "") + ": " + meta.title;
      },
    },
    novel_title: {
      type: "string",
      label: "Title",
      readOnly: true,
      select(e) {
        return e?.novel?.title;
      },
    },
    title: "string",
    unlock_mode: {
      type: "string",
      stringType: "shorttext",
    },
    options: {
      F: "Free",
      C: "Coins Only",
      S: "Subscription Only",
      A: "Coins and Subscription",
    },
    updated_at: "datetime",
    created_at: {
      type: "datetime",
      label: "Date Created",
    },
    lead: {
      type: "string",
    },
    draft_state: {
      type: "string",
      stringType: "shorttext",
    },
  },
});

const novels = createSpec({
  restURL: "/novels/admin",
  label: "Novel",
  urlLookup: "novelId",
  columns: {
    id: "number",
    title: "string",
    author: {
      type: "reference",
      referenceSpec: person,
    },

    cover_image: "image",
    cover_image_width: "number",
    cover_image_height: "number",
    description: {
      type: "string",
      stringType: "longtext",
    },

    num_views: { type: "number", label: "Views" },
    num_stars: { type: "number", label: "Stars" },
    num_words: { type: "number", label: "Words" },
    num_chapters: { type: "number", label: "Chapters" },
    num_pages: { type: "number", label: "Pages" },
    num_reviews: { type: "number", label: "Reviews" },
    num_writing_quality: { type: "number", label: "Writing Quality" },
    num_update_stability: { type: "number", label: "Update Stability" },
    num_story_development: { type: "number", label: "Story Development" },
    num_character_design: { type: "number", label: "Character Design" },
    num_world_background: { type: "number", label: "World Background" },
    num_collections: { type: "number", label: "Collections" },
    num_gifts_received: { type: "number", label: "Gifts Received" },
    num_draft_chapters: { type: "number", label: "Draft Chapters" },
    num_draft_words: { type: "number", label: "Draft Words" },
    tags: {
      type: "list",
      listType: {
        type: "reference",
        referenceSpec: tags,
      },
    },
    language: {
      type: "string",
      stringType: "shorttext",
      options: {
        en: "English",
      },
    },
    genres: {
      type: "list",
      label: "Category",
      listType: {
        type: "reference",
        referenceSpec: genres,
      },
    },
    completed: "boolean",
    updated_at: "datetime",
    leading_gender: {
      type: "string",
      stringType: "shorttext",
      options: {
        M: "Male",
      },
    },
    age_range: "string",
    on_shelf: "boolean",
    approved: "boolean",
    contract_status: {
      type: "string",
      label: "Status",
      stringType: "shorttext",
      options: {
        awaiting_review: "Awaiting Review",
        null: "Not Applied",
        approved: "Approved",
        rejected: "Rejected",
      },
    },
    editor: {
      type: "reference",
      referenceSpec: staff,
    },
  },
});

export const resources = { users, novels, chapters, staff };
